/**
 * Controleur de l'autocompletion de la recherche et du rechargement Ajax
 * */
class dynamicSearch {

    items;
    moteur;

    selectors;
    autocomplete; //Tableau contenant tous les éléments de l'autocompletion
    filtered; //Tableau des éléments déjà filtrés et pas à rajouter dans la recherche

    options = {
        selectors : {
            autocompleteList: '.js-autocomplete-list',
            completionZone: '.js-autocomplete-input-text',
            filterZone: '.js-autocomplete-current-filters',
            items: '.js-autocomplete-item',
        },
    };

    constructor($elem, options) {

        this.moteur = $elem;

        options = $.extend(true, this.options, options);
        this.options = options;
        this.selectors = options.selectors;
        this.filtered = [];

        // on regarde si les selecteurs indispensables sont bien présents dans la page,
        // sinon cette lib ne sera pas fonctionnelle
        if( document.querySelector(this.selectors.completionZone) === null
            || document.querySelector(this.selectors.filterZone) === null
            || document.querySelector(this.selectors.items) === null
        ) {
            return;
        }


        this.loadItems();

        if(this.items.length < 1) {
            return;
        }

        this.keyListenner();
        this.removeFilter();
        this.addFilter();

    }

    loadItems() {

        var items = {};

        $(this.selectors.items,this.moteur).each(function () {
            var item = {
                id: $(this).attr('id'),
                value: $(this).attr('value'),
                slug: $(this).attr('name'),
                name: $(this).data('label')
            };
            items[item.slug] = item;
        })

        this.items = items;
    }

    /**
     * On écoute le champ de recherche pour
     * */
    keyListenner () {

        var that = this;

        this.moteur.on('input', function () {
            var val = $(that.selectors.completionZone, this).val();
            that.compare(val);
        });
    }


    /**
     * On compare la valeur du champ input avec notre json de terms de recherche
     * */
    compare(val) {
        var that = this;

        this.autocomplete = []; //On reset le tableau de recherche pour mettre les nouvelles valeurs suite au changement du champ;

        $.each(this.items, function (i, obj) {
            //Si la valeur dans le champ recherche est équivalente au début d'un des élément du tableau de recherche
            if (obj.name.toLowerCase().indexOf(val.toLowerCase()) === 0 && val !== '') {
                //On vérifie qu'on a pas déjà ajouté le filtre
                if ($.inArray(i, that.filtered) < 0) {
                    that.autocomplete.push(i);
                }
            }
        });

        this.autocompleteField(val);
    }

    /**
     * On remplis la zone d'autocompletion
     * */
    autocompleteField(val) {

        var elements = "";

        var that = this;

        $(this.selectors.autocompleteList,this.moteur).empty(); //On vide le champ pour le recréer proprement

        //On créér les <li> pour mettre dans la liste des résultats
        $.each(that.autocomplete, function (i, v) {
            elements += '<li data-slug="' + that.items[v]['slug'] + '">';
            elements += that.items[v]['name'].substr(0, val.length);
            elements += that.items[v]['name'].substr(val.length,that.items[v]['name'].length);
            elements += '</li>';
        });

        $(this.selectors.autocompleteList,this.moteur).append(elements);

        if (that.autocomplete.length > 0) {
            $(this.selectors.autocompleteList,this.moteur).addClass('show');
        } else {
            $(this.selectors.autocompleteList,this.moteur).removeClass('show');
        }

        this.addFilter(); //On le réinit quand on modifie les options dispo dans le champ autocomplete
    }

    /**
     * Ecoute d'ajout de filtres via l'outil de recherche dynamique
     * */
    addFilter() {

        var that = this;


        $(this.selectors.autocompleteList+' li',this.moteur).off('click'); //On supprime tous les events au clics
        $(this.selectors.autocompleteList+' li',this.moteur).on('click', function () {
            //On copie pour éviter de copier l'event click au passage
            var copy = this;
            $(this).remove();
            $(that.selectors.filterZone,this.moteur).append(copy);

            var slug = $(this).attr('data-slug');
            that.filtered.push(slug);//On ajoute le slug du filtre en question dans un tableau pour éviter d'avoir à gérer des doublons

            if ( $(that.selectors.completionZone+' li',that.moteur).html() == '') {
                $(that.selectors.completionZone+' li',that.moteur).removeClass('show');
            }

            that.removeFilter();

            ($(that.selectors.items+'[name='+slug+']',that.moteur)).prop('checked','checked').trigger('force-change');

        });

    }

    /**
     * Ecoute de la suppression d'un filtre via la liste des filtres déjà ajoutés
     * */
    removeFilter () {

        var that = this;

        $(this.selectors.filterZone+' li',this.moteur).off('click'); //On supprime tous les events au clics
        $(this.selectors.filterZone+' li',this.moteur).on('click', function () {

            var slug = $(this).attr('data-slug');
            $.each(that.filtered, function (i, obj) {
                if (obj == slug) {
                    that.filtered.splice(i, 1);
                }
            });

            $(this).remove();

            var slug = $(this).attr('data-slug');
            ($(that.selectors.items+'[name='+slug+']',that.moteur)).prop('checked','').trigger('force-change');
        });
    }
};

if($('.js-autocomplete').length > 0) {
    $('.js-autocomplete').each(function () {
        new dynamicSearch($(this));
    })
}