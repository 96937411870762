function initMiseEnAvantSlider() {
    var sliders = document.querySelectorAll('.bloc-mise-en-avant .th-slider:not(.no-autoload)');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initGalerie(slider);
    }


    function initGalerie(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelectorAll('.nav-buttons button');

        var reverse = 0;
        if (slider.className.match(/reverse/) && !isTabletPortraitOrSmalller()) {
            reverse = 1;
        }

        var s = new thSlider(slider, {
            draggable: true,
            rtl: reverse,
            scrollListener: true,
            oninit: function (slider) {
                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }
            },
            onchange: function (slider) {
                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
            }
        });

    }
}


function isSliderMiseEnAvantReady() {
    if (typeof thSlider === "undefined") {
        setTimeout(isSliderMiseEnAvantReady, 1000);
        return false;
    }
    initMiseEnAvantSlider();
}

isSliderMiseEnAvantReady();

