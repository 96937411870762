document.addEventListener("DOMContentLoaded", () => {
    if (!isMobileOrSmaller()) {
        const parallaxElements = document.querySelectorAll("[data-parallax]");

        if (parallaxElements && parallaxElements.length) {
            const parallax = new thParallax(parallaxElements, 0.1);

            parallax.init();
        }
    }
});
