$('form input[type="text"], form textarea, form input[type="number"], form input[type="email"], form input[type="url"], form input[type="tel"], form input[type="password"], form .selectric-wrapper').each(function () {

    var _this = $(this);

    _this.prev('label').addClass('input-label');

    // A l'initialisation, si on a déjà une valeur (pas pour le selectric)
    if (_this.val() || _this.attr('placeholder')) {
        _this.parent().addClass('focused');
    }
    if (_this.hasClass('selectric-wrapper')) {
        _this.parent().addClass('focused');
    }

    //Focus in et out pour déplacer le label
    _this.on('focus', function () {
        _this.parent().addClass('focused');
    });
    _this.on('focusout', function () {
        setTimeout(function () {
            if (!_this.val() && !_this.hasClass('selectric-wrapper') && !_this.attr('placeholder')) {
                _this.parent().removeClass('focused');
            }
        }, 200);
    });

    //Au hover on colorise le label
    _this.on('hover', function () {
        _this.prev('label').addClass('hover');
    });

    //si il y a un erreur, n rajoute une classe pour changer le style
    if (_this.parent().find('.frm_error').length > 0) {
        _this.parent().addClass('error');
    }


});


(function () {

    // Quand une checkbox / radio est coché, ajoute une classe sur le container parent
    $('.bloc-form .frm_checkbox input[type="checkbox"]').on('change', function () {
        if ($(this).is(':checked')) {
            $(this).closest('.frm_checkbox').addClass('is-checked');
        } else {
            $(this).closest('.frm_checkbox').removeClass('is-checked');
        }
    });


    $('.bloc-form .frm_radio input[type="radio"]').on('change', function () {
        var $container = $(this).closest('.frm_opt_container');

        $($container).find('input[type="radio"]').each(function () {
            if (this.checked) {
                $(this).closest('.frm_radio').addClass('is-checked');
            } else {
                $(this).closest('.frm_radio').removeClass('is-checked');
            }
        });
    });


    // Quand un switch est coché, ajoute une classe sur le container parent
    $('.bloc-form .frm_switch_block input[type="checkbox"]').on('change', function () {
        if ($(this).is(':checked')) {
            $(this).closest('.frm_switch_block').addClass('is-checked');
        } else {
            $(this).closest('.frm_switch_block').removeClass('is-checked');
        }
    })

})();
