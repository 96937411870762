class thParallax {
    constructor(elements, easing) {
        this.elements = elements;
        this.parallaxElements = [];

        this.currentScroll = 0;
        this.easing = easing;
    }

    init() {
        this.getElements();
        this.getScroll();
        this.onResize();
        this.render();
    }

    getScroll() {
        window.addEventListener("scroll", () => {
            this.currentScroll = window.scrollY;
        });
    }

    onResize() {
        this.getElements();
    }

    getElements() {
        if (this.parallaxElements.length) {
            this.parallaxElements = [];
        }

        this.elements.forEach(el => {
            const bcr = el.getBoundingClientRect();
            const top = bcr.top + window.scrollY;
            const lastTop = top;

            let parallaxContainer = el.querySelector("div");
            if (!parallaxContainer) {
                el.appendChild(document.createElement("div"));

                parallaxContainer = el.querySelector("div");
            }

            this.parallaxElements.push({
                el, parallaxContainer, top, lastTop
            });
        });
    }

    // linear interpolation
    lerp(a, b, n) {
        return (1 - n) * a + n * b
    }

    render() {
        requestAnimationFrame(() => {
            this.parallaxElements.forEach((el, index) => {
                if (!this.parallaxElements[index].parallaxContainer.classList.contains("visible")) {
                    this.parallaxElements[index].parallaxContainer.classList.add("visible");
                }

                this.parallaxElements[index].lastTop = this.lerp(this.parallaxElements[index].lastTop, (el.top - this.currentScroll) / 1.25, this.easing);

                this.parallaxElements[index].parallaxContainer.style.transform = `translate3d(0, ${this.parallaxElements[index].lastTop}px, 0)`;
            });

            this.render();
        })
    }
}