/* Le code suivant nous permet d'ouvrir et fermer la chat de Messenger au click sur les boutons correspondants.
* Dans la version mobile on a pas deux événements différents vu que la chat de Messenger couvre toute la page, donc on a plus besoin du bouton original pour la fermer
* Les fonctions "FB.CustomerChat.showDialog" et "FB.CustomerChat.hideDialog()" sont expliqués dans la documentation:
* Lien : https://developers.facebook.com/docs/messenger-platform/discovery/customer-chat-plugin/sdk/?locale=fr_FR
*/

$(document).ready(function () {
    if (window.innerWidth >= 1280) {

        function showChat() {
            FB.CustomerChat.show({shouldShowDialog: true});
            $(".fb-mobile-chat").one("click", hideChat);
        }

        function hideChat() {
            FB.CustomerChat.hideDialog();
            $(".fb-mobile-chat").one("click", showChat);
        }

        $(".fb-mobile-chat").one("click", showChat);

    } else { // viewportWidth width < 1280
        $(".fb-mobile-chat").on('click', function () {
            FB.CustomerChat.showDialog();
            $('.fb_iframe_widget iframe').css('display', 'block');
        });
    }

});
