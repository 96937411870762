var popinPub = {
    eventTriggered: false,
    idmodal: 'overlay-popin',
    nb_pages: 3,

    hasLocalStorage: false,
    hasgaq: false,

    autoDisplay: false,

    init: function () {
        popinPub.testLocalStorage();
        popinPub.testGaq();

        if (popin_settings.nb_pages > 0) {
            popinPub.nb_pages = popin_settings.nb_pages;
        }

        if (!popinPub.hasLocalStorage) {
            return false;
        }

        $('.form-sent').hide();
        var viewCount = popinPub.incrementPageCount();

        if (popinPub.checkAlreadySee()) {
            return false;
        }

        if (popin_settings.enabled) {
            if (viewCount >= popinPub.nb_pages) {
                if (popin_settings.display_mouse_leave) {
                    popinPub.listenEventCloseTab();
                } else {
                    popinPub.open();
                }
            }
        }
    },

    testGaq: function () {
        if (typeof window._gaq != 'undefined') {
            popinPub.hasgaq = true;
        }
    },

    testLocalStorage: function () {
        try {
            localStorage.setItem('hasLocalStorage', 'oui');
            localStorage.removeItem('hasLocalStorage');
            popinPub.hasLocalStorage = true;
        } catch (e) {
            popinPub.hasLocalStorage = false;
        }
    },

    incrementPageCount: function () {
        var count = localStorage.getItem('popinpub-pagevue');
        if (count == null) {
            count = 1;
        } else {
            count = parseInt(count) + 1;
        }
        localStorage.setItem('popinpub-pagevue', count);

        return count;
    },

    checkAlreadySee: function () {
        var alreadySee = localStorage.getItem('popinpub-already');
        return alreadySee !== null;
    },

    listenEventCloseTab: function () {
        $("body").on("mouseleave", function (e) {
            if (e.offsetY - $(window).scrollTop() < 0 && !popinPub.eventTriggered) {
                popinPub.eventTriggered = true;
                $("body").off('mouseleave');
                popinPub.open();
            }
        });
    },

    open: function () {
        $('#' + popinPub.idmodal + ' form').submit(function (e) {
            e.preventDefault();
            popinPub.confirmSubmit();
        });

        popinPub.openModal();

        setTimeout(function () {
            localStorage.setItem('popinpub-already', '1');
        }, 500)
    },

    openModal: function () {

        if (popinPub.hasgaq) {
            window._gaq.push(['_trackEvent', 'Pop-in publicitaire', 'Affichage', 'Pages vues avant : ' + localStorage.getItem('popinpub-pagevue')]);
        }

        setTimeout(function () {
            th_overlay.open(popinPub.idmodal);
        }, 100);
    },


    addError: function (errormsg) {

        var p = document.createElement('p');

        p.innerHTML = errormsg;
        p.className = 'errormsg';
        p.style.opacity = 0;

        $('#nlModal form').prepend(p);

        setTimeout(function () {
            $(p).animate({opacity: 1}, 300);
        }, 25);

        setTimeout(function () {
            $(p).animate({opacity: 0}, 300);
            setTimeout(function () {
                p.parentNode.removeChild(p);
            }, 400);
        }, 3000);
    },

    confirmSubmit: function () {

        var email = $('#field_mail_popin').val();

        if (popinPub.hasgaq) {
            window._gaq.push(['_trackEvent', 'Pop-in publicitaire', 'Click', 'Bouton']);
        }

        if ($('.frm_required_field input:checked').length > 0) {
            jQuery.post(document.location.origin + '/wp-admin/admin-ajax.php', {
                action: "popinpub",
                email: email
            }, function (response) {
                response = $.parseJSON(response);
                if (!response.success) {
                    popinPub.addError(response.error[0]);
                } else {
                    $('.content.form').hide();
                    $('.form-sent').show();

                    if (popinPub.hasgaq) {
                        window._gaq.push(['_trackEvent', 'Pop-in publicitaire', 'Conversion', '1']);
                    }
                }
            });
        } else {
            $('.frm_required_field').append('<span class="error">' + $('.frm_required_field input').data('reqmsg') + '</span>');
        }
    }
};

popinPub.init();
