(function () {

    /*
        ! ATTENTION !
        Ce slider est présent une seul fois dans la page
        Ce code n'est pas utilisable pour du bloc
    */

    var sliderEl = document.querySelector('.cover-home .th-slider');

    if (sliderEl) {

        var navContainerEl = document.querySelector('.cover-home .nav-buttons');
        var dotsContainerEl = document.querySelector('.cover-home .nav-dots');

        var s = new thSlider(sliderEl, {
            draggable: true,
            scrollListener: true,
            scrollModeMaxWidth: 1024,


            oninit: function (slider) {
                if (navContainerEl) {
                    thSliderTools.onInitNav(slider, navContainerEl.children);
                }
                if (dotsContainerEl) {
                    thSliderTools.onInitDots(slider, dotsContainerEl);
                }

                currentSlide = 0;
            },


            onchange: function (slider) {
                if (navContainerEl) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navContainerEl.children);
                }
                if (dotsContainerEl) {
                    thSliderTools.onChangeDots(slider, dotsContainerEl);
                }

                isActive(slider);
            },

            beforechange: function (slider) {
                isActive(slider);
            }

        });

        // Ajout d'une class active pour mettre un style différent
        function isActive(slider) {
            for (i = 0; i < slider.state.items.length; i++) {
                if (slider.state.current == i) {
                    slider.state.items[i].classList.add("is-active");
                } else {
                    slider.state.items[i].classList.remove("is-active");
                }
            }
        }
    }

})();