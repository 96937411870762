(function(){

    var sliders = document.querySelectorAll('.bloc-pubs.th-slider-wrapper .th-slider:not(.no-autoload)');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }

    function initSlider(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }

        var navButtons = parent.querySelector('.bloc-pubs .nav-buttons');
        var dotsWrapper = parent.querySelector('.bloc-pubs .nav-dots-2');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,

            oninit: function (slider) {
                if (navButtons) {
                    thSliderTools.onInitNav(slider, navButtons.children);
                }

                if (navButtons) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons.children);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper);
                }
            },
            beforechange: function (slider) {
                if (navButtons) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons.children);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper);
                }
            }
        });
    }

})();
