const carteInteractive = {
    selector: {
        form: "#carte_interactive_facettes",
        map: "#carte_interactive_map",
        btnCloseFiltres: "#close_filtres_carte",
        btnOpenFiltres: "#open_filtres_carte"
    },

    map: null,
    markers: [],

    init: function () {
        carteInteractive.carteEvent();
    },

    carteEvent: function () {
        th_overlay.addCallbackOpen(function (overlay_id) {
            if (overlay_id === "overlay-carte-interactive") {
                const el = $("#" + overlay_id);

                if (!el.hasClass("parsed")) {
                    carteInteractive.loadMap();

                    el.addClass("parsed");
                }

                if (isMobileOrSmaller()) {
                    if (!$(".overlay-carte-interactive__content").hasClass("is-hidden")) {
                        $(".overlay-carte-interactive__content").addClass("is-hidden");
                    }
                } else {
                    $(".overlay-carte-interactive__content").removeClass("is-hidden");
                }
            }
        });

        if (window.location.href.indexOf('#overlay-carte-interactive') !== -1) {
            setTimeout(function () {
                carteInteractive.loadMap();

                $("#overlay-carte-interactive").addClass("parsed");
            }, 350);
        }

        $(carteInteractive.selector.btnCloseFiltres).on("click", function () {
            $(".overlay-carte-interactive__content").addClass("is-hidden");
        });

        $(carteInteractive.selector.btnOpenFiltres).on("click", function () {
            $(".overlay-carte-interactive__content").removeClass("is-hidden");
        });
    },

    loadMap: function () {
        carteInteractive.map = th_maps.initSingleMap(document.querySelector(carteInteractive.selector.map));

        $.ajax({
            url: "/wp-admin/admin-ajax.php",
            method: "get",
            data: {
                action: "get_carte_interactive"
            }
        })
            .then(function (response) {
                const res = JSON.parse(response);

                carteInteractive.loadPoints(carteInteractive.map, res.data.points);
                carteInteractive.appendHTML(carteInteractive.selector.form, res.data.HTMLInputs);

                carteInteractive.initDropdowns();
                carteInteractive.inputEvents();
                carteInteractive.openByDefault();
            });
    },

    loadPoints: function (map, json) {
        $.each(json, function () {
            const selection = this.selection_id[0];

            let icon = null;

            if (this.icon) {
                icon = "icon-" + selection;

                th_maps.addMarkerIcon(icon, {
                    iconUrl: this.icon.url,
                    shadowUrl: null,
                    iconSize: [25, 25],
                    iconAnchor: [13, 13]
                });
            }

            $.each(this.points, function () {
                const marker = th_maps.createMarker(null, {
                    lat: this.lat,
                    lng: this.lng
                }, icon ? icon : 'default', 'selection-' + selection);
                const lat = this.lat;
                const lng = this.lng;
                const post_id = this.id;

                carteInteractive.markers.push(marker);

                marker.on('click', function () {
                    if (carteInteractive.lastActiveMarker) {
                        if (icon) {
                            carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons[icon]);
                        } else {
                            carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons['default']);
                        }
                        carteInteractive.lastActiveMarker = null;
                    }
                    marker.setIcon(th_maps.markersIcons['hover']);
                    carteInteractive.lastActiveMarker = marker;

                    $.ajax({
                        url: "/wp-admin/admin-ajax.php",
                        method: "POST",
                        data: {
                            action: "get_carte_interactive_point",
                            post_id: post_id
                        },
                        success: function (html) {
                            const response = JSON.parse(html);
                            const content = response.html;

                            const infoWindow = th_maps.createInfoWindow(content);
                            infoWindow.on('remove', function () {
                                if (icon) {
                                    carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons[icon]);
                                } else {
                                    carteInteractive.lastActiveMarker.setIcon(th_maps.markersIcons['default']);
                                }
                            });
                            infoWindow.setLatLng({lat: lat, lng: lng}).openOn(map);

                            const lazyLoadInstance = new LazyLoad({
                                elements_selector: ".lazy"
                            });

                            th_favoris.parseItems();
                            th_favoris.addEvents();
                        }
                    });
                })

            })
        });

        const group = new L.featureGroup(carteInteractive.markers);
        carteInteractive.map.fitBounds(group.getBounds(), {padding: [50, 50]});

        $(carteInteractive.selector.form).on('submit', function (e) {
            e.preventDefault();
            th_overlay.close('overlay-filtres-carte');
        });
    },

    appendHTML: function (container, content) {
        if (container && content) {
            document.querySelector(container).innerHTML = content;

            // pour les tards gtranslate
            for (const [key, group] of Object.entries(map_filters)) {
                $('[data-js-trad-filters=' + group.filter.id + ']').text(group.filter.name);
                for (const [key_filter, filter] of Object.entries(group.filters)) {
                    $('[for=' + filter.id + '] i').text(filter.name);
                }
            }
        }
    },

    initDropdowns: function () {
        // accordeon des filtres
        $('.group-checkbox > legend', carteInteractive.selector.form).on("click", function () {
            if (this.parentNode.classList.contains("is-active")) {
                this.parentNode.classList.remove("is-active");
            } else {
                this.parentNode.classList.add("is-active");
            }
        });

        $('.frm_checkbox > label', carteInteractive.selector.form).append('<span></span>');
    },

    inputEvents: function () {
        $("input[type='checkbox']", $(carteInteractive.selector.form)).on("change", function () {
            const el = this;
            setTimeout(function () {
                if (el.checked) {
                    th_maps.showGroupMarker('selection-' + el.value, carteInteractive.map);
                } else {
                    th_maps.hideGroupMarker('selection-' + el.value);
                }
            }, 50);
        });

        $("input[type='checkbox']", $(carteInteractive.selector.form)).on("force-change", function () {
            const el = this;
            setTimeout(function () {
                if (el.checked) {
                    th_maps.showGroupMarker('selection-' + el.value, carteInteractive.map);
                } else {
                    th_maps.hideGroupMarker('selection-' + el.value);
                }
            }, 50);
        });
    },

    openByDefault: function () {
        $("input[type='checkbox']", $(carteInteractive.selector.form)).each(function () {
            if (this.getAttribute("data-display-default")) {
                $(this).click();
            }
        });
    }
};

carteInteractive.init();